import * as yup from 'yup';
import { TELECONSULTOR, REGIAO } from './fieldNames';

export default (strings) => {
    return yup.object().shape({
        [REGIAO]: yup
            .object()
            .nullable()
            .when('ehAprovacao', {
                is: (val) => !val,
                then: yup
                    .object().shape({
                        id_cadastro_regiao_autorregulacao: yup.number(),
                        descricao: yup.string(),
                    })
                    .nullable()
                    .required(strings.campoObrigatorio)
            }),
        [TELECONSULTOR]: yup
            .object().shape({
                id: yup.number(),
                nome: yup.string(),
            })
            .nullable()
            .required(strings.campoObrigatorio)
    });
}