import React, { useEffect, useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import StickyBox from "react-sticky-box";
import axios from 'axios';
import HttpStatus from 'http-status-codes';
import { getHeaders } from '../../request';
import {
    SttLoading,
    SttGrid,
    SttHidden,
    SttTranslateHook
} from '@stt-componentes/core';
import Form from './form';
import FormSecundario from './formSecundario';
import Detalhes from '../visualizacao/detalhes';
import Functions from '../../common/Functions';
import { SITUACAO_TELECONSULTORIA } from '../../common/Constants';
import { useSignal, useSignals } from '@preact/signals-react/runtime';
import usuario from '../../signals/usuario';
import alerta from '../../signals/alerta';

const useStyles = makeStyles(theme => ({
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    }
}));

const ContainerResposta = ({ id, idAba, removerAba, adicionarAba }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    useSignals();

    const teleconsultoria = useSignal(null);

    useEffect(() => {
        if (id) {
            let tipoAlerta = '';
            let tituloAlerta = '';
            let mensagemAlerta = '';
            let options = [];
            let onClose = () => { };
            let exibirAlerta = false;

            axios.get(`${global.gConfig.url_base_teleconsultorias}/solicitacao/${id}`, { headers: getHeaders() })
                .then((response) => {
                    if (response.data) {
                        let { data } = response.data;

                        if (data.situacao >= SITUACAO_TELECONSULTORIA.SITUACAO.AGUARDANDO_AVALIACAO) {
                            tipoAlerta = 'alert';
                            exibirAlerta = true;
                            tituloAlerta = strings.atencao;
                            mensagemAlerta = strings.erroJaRespondida;
                            options = [{
                                title: strings.ok,
                                onClick: () => {
                                    removerAba();
                                    alerta.value = {
                                        ...alerta.value,
                                        open: false
                                    }
                                }
                            }];
                            onClose = () => {
                                removerAba();
                                alerta.value = {
                                    ...alerta.value,
                                    open: false
                                };
                            }
                            return;
                        }

                        data.acoes = Functions.acoes(usuario, data);
                        data = Functions.formatarParaVisualizacao(data);

                        data.ehRespostaSecundaria = data.segundaOpiniao.teleconsultoresSecundarios.some(ts => ts.id_funcionario_teleconsultor === usuario.value.idFuncionario);

                        teleconsultoria.value = data;
                    }
                })
                .catch(err => {
                    console.log(err);
                    const { response } = err;
                    exibirAlerta = true;
                    tipoAlerta = 'error';
                    tituloAlerta = strings.erro;
                    mensagemAlerta = strings.erroGenerico;

                    if (response) {
                        const { data } = response;

                        mensagemAlerta = data.message;
                        if (response.status === HttpStatus.BAD_REQUEST || response.status === HttpStatus.INTERNAL_SERVER_ERROR) {
                            let arrMensagem = [];
                            data.errors.forEach(error => {
                                arrMensagem.push(`- ${error.message}`);
                            });
                            if (arrMensagem.length > 0) {
                                mensagemAlerta = arrMensagem.join('\n');
                            }
                        }
                    }

                    options = [{
                        title: strings.ok,
                        onClick: () => {
                            alerta.value = {
                                ...alerta.value,
                                open: false
                            }
                        }
                    }];
                    onClose = () => {
                        alerta.value = {
                            ...alerta.value,
                            open: false
                        };
                    }
                })
                .finally(() => {
                    alerta.value = {
                        ...alerta.value,
                        type: tipoAlerta,
                        title: tituloAlerta,
                        message: mensagemAlerta,
                        open: exibirAlerta,
                        options: options,
                        onClose: onClose
                    }
                });
        }
    }, [id]);

    return (
        teleconsultoria.value ?
            <SttGrid container spacing={2}>
                <SttHidden mdUp>
                    <SttGrid item xs={12}>
                        <Detalhes teleconsultoriaInicial={teleconsultoria.value} esconderAcoes={true} esconderNotificacaoRede={true} adicionarAba={adicionarAba} />
                    </SttGrid>
                    <SttGrid item xs={12}>
                        {
                            teleconsultoria.value.ehRespostaSecundaria
                                ?
                                <FormSecundario teleconsultoria={teleconsultoria.value} removerAba={removerAba} />
                                :
                                <Form teleconsultoria={teleconsultoria.value} removerAba={removerAba} />
                        }
                    </SttGrid>
                </SttHidden>
                <SttHidden only={['xs', 'sm']}>
                    <SttGrid item xs={6}>
                        <StickyBox>
                            <Detalhes teleconsultoriaInicial={teleconsultoria.value} esconderAcoes={true} esconderNotificacaoRede={true} adicionarAba={adicionarAba} />
                        </StickyBox>
                    </SttGrid>
                    <SttGrid item xs={6}>
                        {
                            teleconsultoria.value.ehRespostaSecundaria
                                ?
                                <FormSecundario teleconsultoria={teleconsultoria.value} removerAba={removerAba} />
                                :
                                <Form teleconsultoria={teleconsultoria.value} removerAba={removerAba} />
                        }
                    </SttGrid>
                </SttHidden>
            </SttGrid>
            :
            <div className={classes.carregando}>
                <SttLoading
                    open={!teleconsultoria}
                    text={strings.carregando}
                />
            </div>
    );
};

export default ContainerResposta;